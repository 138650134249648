// 邓亚鑫-V2.2.1
<template>
  <div class="page-add organizational-structure-page">
    <a-form ref="formRef" :rules="rules" :model="modalVal" class="form-add">
      <a-form-item label="人员姓名" name="staffName" class="form-item">
        <a-input
          :maxlength="30"
          placeholder="请输入人员姓名"
          v-model:value="modalVal.staffName"
          class="form-item-width"
        />
      </a-form-item>
      <a-form-item label="账号" name="account" class="form-item" style="margin-bottom: 0">
        <a-input
          :maxlength="11"
          placeholder="请输入人员手机号用于账号登录"
          v-model:value="modalVal.account"
          @change="modalVal.account = modalVal.account.replace(/\D/g, '')"
          class="form-item-width"
        />
      </a-form-item>
      <p class="tips" style="margin-left: 82px; margin-bottom: 16px">
        新账号无密码，人员可使用手机验证码登录后自行设置密码。如果该用户已存在其他企业，使用原密码。
      </p>
      <a-form-item label="角色" name="role" class="form-item">
        <a-select
          v-model:value="modalVal.role"
          placeholder="请选择角色"
          show-search
          allowClear
          :options="roleList"
          :filterOption="filterOption"
          class="form-item-width"
        >
          <!-- <a-select-option v-for="item in roleList" :value="item.id" :key="item.id">{{ item.name }}</a-select-option> -->
        </a-select>
        <p class="tips">若未选择角色，人员默认拥有用印管理和审批中心两个基本权限</p>
      </a-form-item>

      <a-form-item name="departIds" label="部门" class="form-item form-item-cascader">
        <!-- :dropdownStyle="{ 'max-height': '43vh' }" -->
        <!-- <a-tree-select
          :allowClear="true"
          style="width: 468px"
          v-model:value="modalVal.departIds"
          :dropdown-style="{ maxHeight: '43vh' }"
          :tree-data="organizationalList"
          placeholder="请选择部门"
          treeNodeFilterProp="title"
          :replaceFields="{
            children: 'childrenDepartmentList',
            title: 'departmentName',
            value: 'departmentId'
          }"
        >
        </a-tree-select> -->

        <a-cascader
          :allowClear="false"
          changeOnSelect
          popupClassName="depatment-cascader"
          style="width:468px"
          v-model:value="modalVal.departIds"
          :options="organizationalList"
          :display-render="displayRender"
          expand-trigger="hover"
          placeholder="请选择部门"
          @change="cascaderChange"
          :field-names="{
            children: 'childrenDepartmentList',
            label: 'departmentName',
            value: 'departmentId'
          }"
        />
      </a-form-item>
      <a-form-item label="部门主管" name="isLeader" class="form-item">
        <a-select v-model:value="modalVal.isLeader" class="form-item-width" :disabled="isLeaderDisabled">
          <a-select-option v-for="item in isLeaderOptions" :key="item.id" :value="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="" class="form-item">
        <section style="margin-left: 82px">
          <a-button class="cancel-btn" @click="handleCancel">取消</a-button>
          <a-button class="submit-btn" :loading="loading" @click="comfirmAdd">确定</a-button>
        </section>
      </a-form-item>
    </a-form>
    <!-- <a-button @click="handleCancel">取消</a-button>
        <a-button class="comfirm" :loading="loading" @click="comfirmAdd">确定</a-button> -->
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onMounted } from 'vue'
import { cmsNotice } from '@/utils/utils'
import '@/style/page-add.scss'
import { useRouter, useRoute } from 'vue-router'
import { addUser, getDepartmentList, getRoleClassify } from '@/apis/businessManage'

const isLeaderOptions = [
  {
    id: 0,
    name: '否'
  },
  {
    id: 1,
    name: '是'
  }
]
export default defineComponent({
  components: {
    // VirtualList,
    // ApprovalStaffItem
  },
  setup: function(ctx) {
    const formRef = ref()
    const router = useRouter()
    const route = useRoute()
    const modalVal = reactive({
      staffName: undefined,
      account: undefined,
      role: undefined,
      departIds: null,
      isLeader: 0
    })
    const isLeaderDisabled = ref(false)
    const rules = ref()
    rules.value = {
      staffName: [
        {
          required: true,
          message: '请输入人员姓名'
        },
        {
          validator: (rule, value) => {
            var reg = new RegExp(
              '[`~!@#$%^&*()=|{}":;.+_",\\[\\]<>《》/\\\\?～！@#%¥……&*|{}（）【】「」｜‘；：”“。，、？—]'
            )
            var reg1 = new RegExp("['\\-']")
            if (reg.test(value) || reg1.test(value)) {
              return Promise.reject('人员姓名不能有特殊字符')
            }
            return Promise.resolve()
          },
          trigger: 'change'
        }
      ],
      account: [
        {
          required: true,
          message: '请输入人员手机号用于账号登录'
        },
        { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
      ],
      departIds: [
        {
          type: 'array',
          required: true,
          message: '请选择部门'
        }
      ]
    }
    const state = reactive({
      lbwList: [],
      isVirtualListScroll: 0,
      loading: false,
      roleList: [],
      organizationalList: [],
      isShowSreach: false
    })

    // 部门级联选择器显示最后一级部门
    const displayRender = ({ labels }) => {
      return labels[labels.length - 1]
    }
    // 获取部门id路径
    const getIds = id => {
      if (!id) return []
      let ids = []
      const fn = arr => {
        arr.some(item => {
          if (item.departmentId == id) {
            ids.push(id)
            return true
          }
          if (item.childrenDepartmentIdSet.includes(+id)) {
            ids.push(item.departmentId)
            fn(item.childrenDepartmentList)
            return true
          }
          return false
        })
      }
      fn(state.organizationalList)
      return ids
    }

    const getRoleList = async () => {
      // 获取角色列表
      const res = await getRoleClassify()
      // state.roleList = res.data.map()
      if (res.success) {
        state.roleList = res.data.map(item => {
          item.label = item.name
          item.value = item.id
          return item
        })
      }
    }
    const getOrganizationalList = async () => {
      const res = await getDepartmentList()
      if (res.success) {
        state.organizationalList = res.data
        // 部门处理
        const departmentId = +route.query.departmentId
        if (departmentId >= 0) {
          modalVal.departIds = getIds(departmentId)
        }
      }
    }
    const comfirmAdd = () => {
      state.loading = true
      formRef.value
        .validate()
        .then(() => {
          staffAdd()
        })
        .catch(() => (state.loading = false))
    }
    const staffAdd = async () => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(modalVal.account)) {
        cmsNotice('error', '手机号格式错误')
        state.loading = false
        return
      }
      const params = {
        roleId: modalVal.role ? [modalVal.role] : null,
        name: modalVal.staffName.trim(),
        mobile: modalVal.account,
        deptId: [...modalVal.departIds].pop(),
        isLeader: modalVal.isLeader
      }
      const res = await addUser(params)
      if (res.success) {
        cmsNotice('success', '添加成功')
        router.back()
      }
      state.loading = false
    }
    const handleCancel = () => {
      // 关闭弹框
      formRef.value.resetFields()
      router.back()
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) >= 0
    }
    // 部门选择change  部门为企业是部门超管为否切不能修改
    const cascaderChange = value => {
      if (value.length === 1 && value[0] === 0) {
        modalVal.isLeader = 0
        isLeaderDisabled.value = true
      } else {
        isLeaderDisabled.value = false
      }
    }

    onMounted(() => {
      getRoleList()
      getOrganizationalList()
    })

    return {
      ...toRefs(state),
      comfirmAdd,
      formRef,
      modalVal,
      rules,
      handleCancel,
      staffAdd,
      filterOption,
      isLeaderOptions,
      displayRender,
      cascaderChange,
      isLeaderDisabled
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.ant-form-item-label) {
  width: 82px;
}

.tips {
  width: 468px;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
}
</style>
<style lang="scss">
.depatment-cascader {
  .ant-cascader-menu-item {
    width: 6.2em;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
